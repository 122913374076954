import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

const UPLOAD_FILE = gql`
  mutation SingleUpload($file: Upload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
      url
      filepath
      ext
    }
  }
`;

const FileUpload = ({ onUploadComplete }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadDocumentAsync, { loading, error, data }] = useMutation(
    UPLOAD_FILE,
    {
      onCompleted: (data) => {
        if (onUploadComplete) {
          onUploadComplete(data.singleUpload.filepath);
        }
      },
    }
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      try {
        await uploadDocumentAsync({
          variables: {
            file: file,
          },
        });
      } catch (err) {
        console.error("Upload failed", err);
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        className="form-control mb-3"
      />
      {loading ? "Uploading..." : ""}
      {error && <p>Error uploading file: {error.message}</p>}
      {data && <p>File uploaded successfully!</p>}
    </div>
  );
};

export default FileUpload;
