// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/assests/sentinel-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: 'Be Vietnam Pro', sans-serif !important;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
}


.product-overview {
    /* background: rgb(209, 204, 204); */
    /* border: 1.8px solid red;s */
    border-radius: 15px;
}

.error-vesting-text {
    color: red;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/css/styles.css"],"names":[],"mappings":"AAAA;IACI,oDAAoD;IACpD,iFAAkF;AACtF;;;AAGA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,qBAAqB;AACzB","sourcesContent":["body {\n    font-family: 'Be Vietnam Pro', sans-serif !important;\n    background: url(\"../images/assests/sentinel-bg.jpg\") no-repeat center center fixed;\n}\n\n\n.product-overview {\n    /* background: rgb(209, 204, 204); */\n    /* border: 1.8px solid red;s */\n    border-radius: 15px;\n}\n\n.error-vesting-text {\n    color: red;\n    font-size: 14px;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n    word-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
