import { Navigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const ProtectedRoute = ({ element }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user.hasProductRegistered === false) {
    return <Navigate to="/product-register" />;
  }

  return element;
};

export default ProtectedRoute;