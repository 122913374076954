// NewUserRoute.js
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const NewUserRoute = ({ element }) => {
    const { user } = useAuth();
    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.hasProductRegistered === false) {
        return element;
    }

    return <Navigate to="/dashboard" />;
};




export default NewUserRoute;
