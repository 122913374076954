// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    padding: 50px;
    border: 1.8px solid #0044eb;
    border-radius: 20px;
    margin-left: 60px;
    margin-right: 60px;
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px)
}

.sub-label-text-register {
    font-size: 13px;
    color: grey;
    font-weight: 400;

}

.label-text-register {
    font-size: 16px;

}

.form-select {
    cursor: pointer !important;
}

.save-button-register {
    border-radius: 100px !important;
    width: 25% !important;
    margin-bottom: 15px;
    margin-right: 15px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-weight: 800 !important;
}`, "",{"version":3,"sources":["webpack://./src/css/productregister.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,mCAA0B;YAA1B;AACJ;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,+BAA+B;IAC/B,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,4BAA4B;IAC5B,+BAA+B;IAC/B,2BAA2B;AAC/B","sourcesContent":[".register-container {\n    padding: 50px;\n    border: 1.8px solid #0044eb;\n    border-radius: 20px;\n    margin-left: 60px;\n    margin-right: 60px;\n    backdrop-filter: blur(16px)\n}\n\n.sub-label-text-register {\n    font-size: 13px;\n    color: grey;\n    font-weight: 400;\n\n}\n\n.label-text-register {\n    font-size: 16px;\n\n}\n\n.form-select {\n    cursor: pointer !important;\n}\n\n.save-button-register {\n    border-radius: 100px !important;\n    width: 25% !important;\n    margin-bottom: 15px;\n    margin-right: 15px;\n    padding-top: 16px !important;\n    padding-bottom: 16px !important;\n    font-weight: 800 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
