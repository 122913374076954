import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import overallrating from "../../images/dashboard/overall-rating.png";
import review from "../../images/dashboard/reviews-received.png";

import { apiURI } from "../../config";
import { useAuth } from "../../Context/AuthContext";

import AllProducts from "./AllProducts";

import { notifyError } from "../../Components/Alerts";
import Ratings from "./Ratings";

const Dashboard = () => {
  // const sampleProducts = [
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },
  //   {
  //     idea_name: "Product 1",
  //     one_line_desc: "This is a short description of Product 1.",
  //     product_type: "Type A",
  //     category: "Category 1",
  //     logo_url: "logo1.png",
  //     twitter_link: "https://twitter.com/product1",
  //     linkedin_link: "https://linkedin.com/product1",
  //     telegram_link: "https://telegram.me/product1",
  //   },

  //   // Add more product data here...
  // ];

  const [allProducts, setAllProducts] = useState([]);

  const [ratingsData, setRatingsData] = useState([]);
  const [reviewData, setReviewData] = useState({
    overall_rating: 0,
    total_reviewers: 0,
    one_line_statement: 0,
    problem_statement: 0,
    problem_solution: 0,
    whitepaper: 0,
    pitch_deck: 0,
    product: 0,
    competitors: 0,
  });

  // const [currentPage, setCurrentPage] = useState(1);
  // const productsPerPage = 10;

  // const indexOfLastProduct = currentPage * productsPerPage;
  // const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // const currentProducts = sampleProducts.slice(
  //   indexOfFirstProduct,
  //   indexOfLastProduct
  // );
  // const totalPages = Math.ceil(sampleProducts.length / productsPerPage);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("user")).id !== null) {
      const getData = async () => {
        await getDataFromApi();
      };
      getData();
    }
  }, []);

  const getDataFromApi = async () => {
    try {
      var query = `
          query Query($founder: ID!) {
              getProductDetailsByFounder(founder: $founder) {
              ratings {
                  one_line_statement
                  problem_statement
                  solution_statement
                  whitepaper
                  pitch_deck
                  product_rating
                  competitive_advantage
                  product_worth
                  pros
                  cons
                  average_rating
                }  
              average_score
                total_reviews
              }
              allProductsFounder(founder: $founder) {
                _id
                product_name
                product_logo
                one_line_description
                product_type
                twitter_link
                linkedin_link
                telegram_link
                whitepaper_link
                live_product_link
                category {
                  value
                }
              }
            }
          `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            founder: JSON.parse(sessionStorage.getItem("user")).id,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.getProductDetailsByFounder) {
            const api_data = data?.data?.getProductDetailsByFounder;
            // console.log(api_data);
            setReviewData({
              overall_rating: api_data.average_score,
              total_reviewers: api_data.total_reviews,
              one_line_statement: 0,
              problem_statement: 0,
              problem_solution: 0,
              whitepaper: 0,
              pitch_deck: 0,
              product: 0,
              competitors: 0,
            });
            setRatingsData(data?.data?.getProductDetailsByFounder.ratings);
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
          if (data?.data?.allProductsFounder) {
            setAllProducts(data?.data?.allProductsFounder);
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="ms-5 welcome-section">
        <h1>Welcome back, User!</h1>
        <p>Your product reviews at a glance</p>
      </div>

      <section className="product-overview ms-5 me-5 ">
        <div className="d-flex">
          <div className="rating-box w-50 me-5">
            <div>
              <p className="mb-2 rating-text">Overall Rating</p>
              <p className="rating-text">{reviewData.overall_rating} / 5 </p>
            </div>
            <div className="rating-img-div ">
              <img
                src={overallrating}
                alt="rating_img"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
          <div className="rating-box w-50 ">
            <div>
              <p className="mb-2 rating-text">Total Reviewers</p>
              <p className="rating-text">{reviewData.total_reviewers}</p>
            </div>
            <div className="rating-img-div ">
              <img
                src={review}
                alt="rating_img"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </section>

      <Ratings allRating={ratingsData} />

      <br />
      <br />
      <br />
      {/* <section className="product-overview ms-5 me-5">
        <h2>Explore other Sentinal Products</h2>
        <Card />
        <h3>Pagination with cards and search</h3>
      </section> */}

      <div className="ms-5 product-list">
        <h2 className="mb-3">Explore other Sentinel Products</h2>
        {/* <p className="mb-5">Subtext</p> */}

        {/* <div className="cards-container">
          {currentProducts.map((product, index) => (
            <Card key={index} product={product} />
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        /> */}
        <AllProducts allProducts={allProducts} />
      </div>

      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Dashboard;
