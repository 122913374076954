import React, { useState } from "react";
import sentinel_logo from "../../images/assests/sentinel-logo.png";
import emailsent_image from "../../images/assests/emailsent-image.png";

import { IconCircleCheck } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import LoginFooter from "../../Components/LoginFooter";

const PasswordResetConfirmation = () => {
  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block-1 mx-auto mb-1 ">
                <div className="text-center">
                  <h4 className=" mb-1">Password Reset Email Sent</h4>
                  <div>
                    <img
                      id="logo_header"
                      src={emailsent_image}
                      alt="logo-sentinel"
                      width="450"
                      height="auto"
                    ></img>
                  </div>
                  <p className="sub-label-text-verified">
                    We have sent an email to your registered email address with
                    instructions to reset your password.
                  </p>
                  <p className="sub-label-text-verified">
                    Please check your inbox for an email from Sentinel.
                  </p>

                  <br />
                  <br />
                  <p>
                    <strong>Haven't received the email?</strong>
                  </p>
                  <ul>
                    <p className="mb-1">
                      If you haven't received the email within a few minutes,
                      please check your spam or junk folder.
                    </p>
                    {/* <p className="mb-1">Resend verification email</p> */}
                    <p className="mb-1">
                      Contact our support team :{" "}
                      <a
                        href="https://watcher.finance/contact/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "blue" }}
                      >
                        contact@watcher.finance
                      </a>
                    </p>
                  </ul>
                </div>

                <div>
                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirmation;
