import React, { useState, useRef, useEffect } from "react";
import "../css/bulletpoints.css";

import { IconCirclePlus, IconCircleMinus } from "@tabler/icons-react";

const BulletPointsInput = ({ parentState, setParentState }) => {
  const [bulletPoints, setBulletPoints] = useState(parentState || []);
  const lastInputRef = useRef(null);

  const handleAddBulletPoint = (index) => {
    setBulletPoints([...bulletPoints, ""]);
    if (lastInputRef.current) {
      lastInputRef.current.focus();
    }
  };

  const handleBulletPointChange = (event, index) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints[index] = event.target.value;
    setBulletPoints(newBulletPoints);
    setParentState(newBulletPoints);
  };

  const handleRemoveBulletPoint = (index) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints.splice(index, 1);
    setBulletPoints(newBulletPoints);
    setParentState(newBulletPoints);
  };

  return (
    <div>
      {bulletPoints.map((bulletPoint, index) => (
        <div key={index} className="bullet-point-container">
          <span className="me-3">{index + 1}.</span>
          <input
            className="form-control bullet-point-input"
            type="text"
            ref={lastInputRef}
            value={bulletPoint}
            onChange={(event) => handleBulletPointChange(event, index)}
          />
          <button
            className="btn btn-outline-danger remove-button"
            type="button"
            style={{
              display: bulletPoints.length <= 2 ? "none" : "flex",
              //   justifyContent: "space-around",
            }}
            onClick={() => handleRemoveBulletPoint(index)}
          >
            <IconCircleMinus />
          </button>
          <button
            className="btn btn-outline-primary add-button"
            type="button"
            style={{
              display: bulletPoints.length >= 5 ? "none" : "flex",
              //   justifyContent: "space-around",
            }}
            onClick={() => handleAddBulletPoint()}
          >
            <IconCirclePlus />
          </button>
        </div>
      ))}
    </div>
  );
};

export default BulletPointsInput;
