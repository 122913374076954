import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";

import { apiURI } from "../../config";
import {
  productCategories,
  revenueModelsData,
} from "../../Constants/constants";
import MultiSelect from "../../Components/MultiSelect";
import BulletPointsInput from "../../Components/BulletPoints";
import FileUpload from "../../Components/FileUpload";
import { useAuth } from "../../Context/AuthContext";

import { notifyError, notifySuccess } from "../../Components/Alerts";

const MyProduct = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [productName, setProductName] = useState("");
  const [logo, setLogo] = useState(null);
  const [oneLineDesc, setOneLineDesc] = useState("");
  const [productType, setProductType] = useState("");
  const [category, setCategory] = useState([]);
  const [problemStatement, setProblemStatement] = useState(["", ""]);
  const [solutionStatement, setSolutionStatement] = useState(["", ""]);
  const [targetCustomers, setTargetCustomers] = useState(["", ""]);
  const [whitepaper, setWhitepaper] = useState(null);
  const [pitchDeck, setPitchDeck] = useState(null);
  const [revenueModel, setRevenueModel] = useState([]);
  const [solutionBetter, setSolutionBetter] = useState(["", ""]);
  const [competitors, setCompetitors] = useState(["", ""]);
  const [prototypeLink, setPrototypeLink] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [telegramProfile, setTelegramProfile] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    product_name: "",
    one_line_description: "",
    product_type: "",
    product_category: "",
    problem_statement: "",
    revenue_model: "",
  });

  const { user } = useAuth();

  const isValidArray = (arr) =>
    Array.isArray(arr) && arr.length > 0 && arr[0] !== "";

  const handleInputValidation = async () => {
    let hasError = false;

    // Reset all error messages
    setErrorMessage({
      one_line_description: "",
      product_type: "",
      product_category: "",
      problem_statement: "",
    });

    if (!oneLineDesc) {
      setErrorMessage((prevState) => ({
        ...prevState,
        one_line_description:
          "Please enter your product's one line description.",
      }));
      hasError = true;
    }

    if (!productType) {
      setErrorMessage((prevState) => ({
        ...prevState,
        product_type: "Select you Product Type",
      }));
      hasError = true;
    }

    if (category.length <= 0) {
      setErrorMessage((prevState) => ({
        ...prevState,
        product_category: "Select you Product Category",
      }));
      hasError = true;
    }

    const areAllItemsNonEmpty = problemStatement.every((item) => item !== "");
    if (!areAllItemsNonEmpty) {
      setErrorMessage((prevState) => ({
        ...prevState,
        problem_statement: "Please enter your problem statements",
      }));
      hasError = true;
    }

    if (revenueModel.length <= 0) {
      setErrorMessage((prevState) => ({
        ...prevState,
        revenue_model: "Select you Revenue Model Type",
      }));
      hasError = true;
    }

    if (hasError) {
      notifyError("Please fill in the mandatory fields");
      return;
    }
    await handleRegisterApi();
  };

  const handleLogoUpload = (url) => {
    setLogo(url);
  };

  const handleWhitepaperUpload = (url) => {
    setWhitepaper(url);
  };

  const handlePitchDeckUpload = (url) => {
    setPitchDeck(url);
  };

  useEffect(() => {
    const getProductDetails = async () => {
      await getProductDetailsApi();
    };
    getProductDetails();
  }, []);

  const getProductDetailsApi = async () => {
    try {
      var query = `
        query GetProductDetailsByFounder($founder: ID!) {
          getProductDetailsByFounder(founder: $founder) {
            _id
            product_name
            product_logo
            one_line_description
            product_type
            problem_statement
            problem_solution
            target_customers
            whitepaper_link
            pitch_deck_link
            competition_links
            competition_reason
            live_product_link
            twitter_link
            linkedin_link
            telegram_link
            total_reviews
            createdAt
            category {
              value
            }
            revenue_model {
              value
            }
          }
        } 
        `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            founder: user.id,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          console.log("API DATA", data?.data?.getProductDetailsByFounder);
          if (data?.data?.getProductDetailsByFounder) {
            setProductName(data.data.getProductDetailsByFounder.product_name);
            setLogo(data.data.getProductDetailsByFounder.product_logo);
            setOneLineDesc(
              data.data.getProductDetailsByFounder.one_line_description
            );
            setProductType(data.data.getProductDetailsByFounder.product_type);
            // setCategory: data.data.getProductDetailsByFounder.
            setProblemStatement(
              data.data.getProductDetailsByFounder.problem_statement
            );
            setSolutionStatement(
              data.data.getProductDetailsByFounder.problem_solution
            );
            setTargetCustomers(
              data.data.getProductDetailsByFounder.target_customers
            );
            setWhitepaper(data.data.getProductDetailsByFounder.whitepaper_link);
            setPitchDeck(data.data.getProductDetailsByFounder.pitch_deck_link);
            // setRevenueModel(data.data.getProductDetailsByFounder.)
            setSolutionBetter(
              data.data.getProductDetailsByFounder.competition_reason
            );
            setCompetitors(
              data.data.getProductDetailsByFounder.competition_links
            );
            setPrototypeLink(
              data.data.getProductDetailsByFounder.live_product_link
            );
            setTwitterProfile(
              data.data.getProductDetailsByFounder.twitter_link
            );
            setLinkedinProfile(
              data.data.getProductDetailsByFounder.linkedin_link
            );
            setTelegramProfile(
              data.data.getProductDetailsByFounder.telegram_link
            );
            const categoryArray =
              data.data.getProductDetailsByFounder.category.map((item) => ({
                label: item.value,
                value: item.value,
              }));
            setCategory(categoryArray);
            const revenue_model_Array =
              data.data.getProductDetailsByFounder.revenue_model.map(
                (item) => ({
                  label: item.value,
                  value: item.value,
                })
              );
            setRevenueModel(revenue_model_Array);
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductSave = async () => {
    await handleInputValidation();
  };

  const handleRegisterApi = async () => {
    const categoryArray = [];
    for (var i = 0; i < category.length; i++) {
      categoryArray.push({
        value: category[i].value,
      });
    }
    const revenueArray = [];
    for (var j = 0; j < revenueModel.length; j++) {
      revenueArray.push({
        value: revenueModel[j].value,
      });
    }

    try {
      var query = `
        mutation Mutation($input: ProductInputUpdate) {
          updateProduct(input: $input)
        }
        `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            input: {
              founder: JSON.parse(sessionStorage.getItem("user")).id,
              product_logo: logo,
              one_line_description: oneLineDesc,
              product_type: productType,
              category: categoryArray,
              problem_statement: problemStatement,
              problem_solution: solutionStatement,
              target_customers: targetCustomers,
              whitepaper_link: whitepaper,
              pitch_deck_link: pitchDeck,
              revenue_model: revenueArray,
              competition_reason: solutionBetter,
              competition_links: competitors,
              live_product_link: prototypeLink,
              twitter_link: twitterProfile,
              linkedin_link: linkedinProfile,
              telegram_link: telegramProfile,
            },
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.updateProduct) {
            await getProductDetailsApi();
            notifySuccess("Product Updated Successfully");
            setIsEditMode(false);
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      {!isEditMode ? (
        <section className="section validate-page">
          <div className="container">
            <div className="row border-bottom pb-4">
              <div className="logo-div validate">
                <img src={logo} className="logo-validate" alt="logo" />
                <button
                  style={{ float: "right" }}
                  className="btn btn-primary"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit this Project
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="">
                  <div className="content mt-4">
                    <h4 className="mt-3 label-header">
                      Project Name :{" "}
                      <span className="ans-validate">{productName}</span>{" "}
                    </h4>
                    <h4 className="mt-3 label-header">
                      One Line Description :{" "}
                      <span className="ans-validate">{oneLineDesc}</span>{" "}
                    </h4>
                    <h4 className="mt-3 label-header">
                      Product Type :{" "}
                      <span className="ans-validate">{productType}</span>{" "}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <div className="features feature-primary">
                  <div className="content mt-4">
                    <h4 className="label-header mt-3">
                      Category :{" "}
                      <span className="ans-validate">
                        <span className="ans-validate">
                          {category?.[0]?.value ?? "Not Provided"}
                        </span>
                      </span>{" "}
                    </h4>
                    <h4 className="label-header mt-3">
                      White Paper :{" "}
                      {whitepaper === null ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={whitepaper}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View{" "}
                        </a>
                      )}
                    </h4>

                    <h4 className="label-header mt-3">
                      Pitch Deck :{" "}
                      {pitchDeck === null ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={pitchDeck}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View{" "}
                        </a>
                      )}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <div className="features feature-primary">
                  <div className="content mt-4">
                    <h4 className="label-header mt-3">
                      Product Link :{" "}
                      {!prototypeLink ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={prototypeLink}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View Product
                        </a>
                      )}
                    </h4>
                    <h4 className="label-header mt-3">
                      Competition Links:{" "}
                      <span className="ans-validate">
                        {competitors?.[0] ? (
                          <a
                            href={competitors[0]}
                            target="_blank"
                            className="ans-validate"
                            rel="noreferrer"
                          >
                            View Product
                          </a>
                        ) : (
                          "Not Provided"
                        )}
                      </span>{" "}
                    </h4>
                    <h4 className="label-header mt-3">
                      Revenue Module :{" "}
                      <span className="ans-validate">
                        {revenueModel?.[0]?.value ?? "Not Provided"}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4 " style={{ marginTop: "50px" }}>
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 ">
                <div className="row  pb-2 ">
                  <div className="section-title ">
                    <h4 className="title validate-head-ans mb-3">
                      Problem Statement
                    </h4>

                    <ul className="">
                      {isValidArray(problemStatement)
                        ? problemStatement.map((value, index) => {
                            return <li>{value}</li>;
                          })
                        : "Not Provided"}
                    </ul>
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    height: "1px",
                    backgroundColor: "black",
                  }}
                />
              </div>

              <div className="col-lg-12 col-md-12 ">
                <div className="row  pb-2">
                  <div className="section-title ">
                    <h4 className="title validate-head-ans mb-3">
                      Problem Solution
                    </h4>
                    <ul className="">
                      {isValidArray(solutionStatement)
                        ? solutionStatement.map((value, index) => {
                            return <li>{value}</li>;
                          })
                        : "Not Provided"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr
              style={{
                border: "none",
                height: "1px",
                backgroundColor: "black",
              }}
            />
          </div>

          <div className="container mt-4 " style={{ marginTop: "50px" }}>
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 ">
                <div className="row  pb-2 ">
                  <div className="section-title ">
                    <h4 className="title validate-head-ans mb-3">
                      Target Audience
                    </h4>

                    <ul className="">
                      {isValidArray(targetCustomers)
                        ? targetCustomers.map((value, index) => {
                            return <li>{value}</li>;
                          })
                        : "Not Provided"}
                    </ul>
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    height: "1px",
                    backgroundColor: "black",
                  }}
                />
              </div>

              <div className="col-lg-12 col-md-12 ">
                <div className="row  pb-2">
                  <div className="section-title ">
                    <h4 className="title validate-head-ans mb-3">
                      Unique Selling Point
                    </h4>
                    <ul className="">
                      {isValidArray(solutionBetter)
                        ? solutionBetter.map((value, index) => {
                            return <li>{value}</li>;
                          })
                        : "Not Provided"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group text-center mb-5 mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setIsEditMode(true)}
            >
              {" "}
              Edit this Project
            </button>
          </div>
        </section>
      ) : (
        <section className="section validate-page">
          {" "}
          <div className="mt-5 register-container">
            <div className="form-group ">
              <label className="label-text-register mb-3" for="username">
                Your Product Name <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  (Cannot be changed) - Provide the official name of your
                  project/product.
                </span>
              </label>
              <p className="form-control mb-3 " type="text">
                {productName}
              </p>
            </div>

            <div className="form-group  mt-3">
              {logo ? (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Your Logo
                  </label>
                  <br />
                  <img
                    id="logo_header"
                    src={logo}
                    alt="logo"
                    width="150"
                    height="auto"
                  ></img>
                  <button
                    className="btn btn-sm btn-danger ms-5"
                    onClick={() => setLogo(null)}
                  >
                    Remove
                  </button>
                </>
              ) : (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Upload Your Logo
                    <br />
                    <span className="sub-label-text-register">
                      (Recommended size: 512 x 512px) - Upload an image file for
                      your product's logo.
                    </span>
                  </label>

                  <FileUpload onUploadComplete={handleLogoUpload} />
                </>
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Describe Your Product in One Line{" "}
                <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  Keep it simple and precise so that a layman can understand it
                </span>
              </label>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="e.g., A decentralized platform for secure financial transactions"
                value={oneLineDesc}
                onChange={(e) => setOneLineDesc(e.target.value)}
              />
              {errorMessage.one_line_description !== "" ? (
                <p className="error-vesting-text" style={{ width: "100%" }}>
                  {errorMessage.one_line_description}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Select Your Product Type <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  Choose the appropriate type that best describes your product.
                </span>
              </label>
              <select
                class="form-select mb-3"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <option value="" disabled>
                  Select product type
                </option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
                <option value="B2B2C">B2B2C</option>
              </select>
              {errorMessage.product_type !== "" ? (
                <p className="error-vesting-text" style={{ width: "100%" }}>
                  {errorMessage.product_type}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Select Your Category <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  Choose the relevant category that fits your product's industry
                  or function.
                </span>
              </label>
              <MultiSelect
                options={productCategories}
                value={category}
                onChange={setCategory}
                placeholder={"Select Product Category"}
              />
              {errorMessage.product_category !== "" ? (
                <p className="error-vesting-text" style={{ width: "100%" }}>
                  {errorMessage.product_category}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Describe Your Problem Statement{" "}
                <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  (In bullet points) - List the major problems your product is
                  tackling
                </span>
              </label>
              <BulletPointsInput
                parentState={problemStatement}
                setParentState={setProblemStatement}
              />
              {errorMessage.problem_statement !== "" ? (
                <p className="error-vesting-text" style={{ width: "100%" }}>
                  {errorMessage.problem_statement}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Describe Your Solution
                <br />
                <span className="sub-label-text-register">
                  (In bullet points) - Outline the solutions your product is
                  proposing.
                </span>
              </label>
              <BulletPointsInput
                parentState={solutionStatement}
                setParentState={setSolutionStatement}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Identify Your Target Customers
                <br />
                <span className="sub-label-text-register">
                  (Make a list) - Specify your target customers, e.g., financial
                  institutions, hospitals,
                </span>
              </label>
              <BulletPointsInput
                parentState={targetCustomers}
                setParentState={setTargetCustomers}
              />
            </div>

            <div className="form-group  mt-3">
              {whitepaper ? (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Your WhitePaper
                  </label>
                  <br />
                  <a
                    href={whitepaper}
                    alt="whitepaper"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    View WhitePaper
                  </a>
                  <button
                    className="btn btn-sm btn-danger ms-5"
                    onClick={() => setWhitepaper(null)}
                  >
                    Remove Whitepaper
                  </button>
                </>
              ) : (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Do You Have a Whitepaper?
                    <br />
                    <span className="sub-label-text-register">
                      Upload your whitepaper if available (File format: PDF
                      only).
                    </span>
                  </label>

                  <FileUpload onUploadComplete={handleWhitepaperUpload} />
                </>
              )}
            </div>

            <div className="form-group  mt-3">
              {pitchDeck ? (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Your PitchDeck
                  </label>
                  <br />
                  <a
                    href={pitchDeck}
                    alt="PitchDeck"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    View PitchDeck
                  </a>
                  <button
                    className="btn btn-sm btn-danger ms-5"
                    onClick={() => setPitchDeck(null)}
                  >
                    Remove PitchDeck
                  </button>
                </>
              ) : (
                <>
                  <label className="label-text-register mb-3" for="username">
                    Do You Have a Pitch Deck?
                    <br />
                    <span className="sub-label-text-register">
                      Upload your pitch deck if available (File format: PDF
                      only).
                    </span>
                  </label>

                  <FileUpload onUploadComplete={handlePitchDeckUpload} />
                </>
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Select Your Revenue Model{" "}
                <span style={{ color: "red" }}>*</span>
                <br />
                <span className="sub-label-text-register">
                  Select whether you have an established revenue model.
                </span>
              </label>
              <MultiSelect
                options={revenueModelsData}
                placeholder={"Select Your Revenue Model"}
                value={revenueModel}
                onChange={setRevenueModel}
              />
              {errorMessage.revenue_model !== "" ? (
                <p className="error-vesting-text" style={{ width: "100%" }}>
                  {errorMessage.revenue_model}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Why Is Your Solution Better Than Your Competition ?
                <br />
                <span className="sub-label-text-register">
                  (Tell us what makes you different) - Describe the unique
                  aspects of your solution in bullet points.
                </span>
              </label>
              <BulletPointsInput
                parentState={solutionBetter}
                setParentState={setSolutionBetter}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Do You Have Competitors?
                <br />
                <span className="sub-label-text-register">
                  (Update your competitors' website link) - Provide the links to
                  your competitors' websites.
                </span>
              </label>
              <BulletPointsInput
                parentState={competitors}
                setParentState={setCompetitors}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Do You Have a Live Product or Working Prototype?
                <br />
                <span className="sub-label-text-register">
                  (If Yes, please provide the link) - Provide the URL to your
                  product or prototype.
                </span>
              </label>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="e.g.https://app.yourproduct.com"
                value={prototypeLink}
                onChange={(e) => setPrototypeLink(e.target.value)}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Twitter Profile
                <br />
                <span className="sub-label-text-register">
                  Provide the link to your product's Twitter profile.
                </span>
              </label>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="https://twitter.com/yourprofile"
                value={twitterProfile}
                onChange={(e) => setTwitterProfile(e.target.value)}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                LinkedIn Profile
                <br />
                <span className="sub-label-text-register">
                  Provide the link to your product's LinkedIn profile.
                </span>
              </label>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="https://linkedin.com/in/yourprofile"
                value={linkedinProfile}
                onChange={(e) => setLinkedinProfile(e.target.value)}
              />
            </div>

            <div className="form-group mt-3">
              <label className="label-text-register mb-3" for="username">
                Telegram Profile
                <br />
                <span className="sub-label-text-register">
                  Provide the link to your product's Telegram profile.
                </span>
              </label>

              <input
                className="form-control mb-3"
                type="text"
                placeholder="https://telegram.com/profile"
                value={telegramProfile}
                onChange={(e) => setTelegramProfile(e.target.value)}
              />
            </div>

            <br />

            <div className="d-flex justify-content-center">
              <div className="form-group text-center mb-3">
                <button
                  className="btn btn-danger mx-5"
                  onClick={() => setIsEditMode(false)}
                >
                  {" "}
                  Cancel
                </button>
              </div>
              <div className="form-group text-center mb-3 mx-5">
                <button className="btn btn-primary" onClick={handleProductSave}>
                  {" "}
                  Update this Project
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MyProduct;
