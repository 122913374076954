import React, { useState, useEffect } from "react";

import sentinel_logo from "../../images/assests/sentinel-logo.png";
import loading_image from "../../images/assests/loading-image.png";

import LoginFooter from "../../Components/LoginFooter";
import { useParams, useNavigate } from "react-router-dom";

import { apiURI } from "../../config";

import { notifyError } from "../../Components/Alerts";

const Verify_Founder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("VERIFYING FOUNDER");
      handleRegisterApi();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleRegisterApi = async (name, email, password) => {
    try {
      var query = `
                mutation Mutation($id: ID!) {
                  verifyFounder(_id: $id)
                }
        `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            id: id,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.verifyFounder) {
            navigate("/verification-successful");
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            {/* <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div> */}
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block-1 mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text-header-register mb-2">
                    Verifying Your Account
                  </h3>
                  <div>
                    <img
                      id="logo_header"
                      src={loading_image}
                      alt="logo-sentinel"
                      width="500"
                      height="auto"
                    ></img>
                  </div>

                  <h4 className="sub-label-text-verified  mt-4">
                    We are verifying your email address. Please wait a moment
                    while we complete the verification process.
                  </h4>
                </div>

                <br />
                <br />
                <div>
                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify_Founder;
