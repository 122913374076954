import React from "react";

const Footer = () => {
  return (
    <div>
      <hr className="mx-auto" style={{ width: "95%" }} />

      <footer className="ms-5 mt-3 mb-3 d-flex justify-content-center">
        © 2024
        <a
          className="ms-2 me-2"
          href="https://watcher.finance"
          target="_blank"
          rel="noreferrer"
        >
          Watcher.Finance
        </a>
        , All Rights Reserved.
      </footer>
    </div>
  );
};

export default Footer;
