import React, { useState } from "react";

import sentinel_logo from "../../images/assests/sentinel-logo.png";

import { Link, useNavigate } from "react-router-dom";
import { apiURI } from "../../config";

import { notifyError, notifySuccess } from "../../Components/Alerts";

import LoginFooter from "../../Components/LoginFooter";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    await handleForgotPasswordApi(email);
  };

  const handleForgotPasswordApi = async (email) => {
    try {
      var query = `
                mutation ForgotPassword($email: String!) {
                    forgotPassword(email: $email)
                  }
          `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            email,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.forgotPassword) {
            notifySuccess(data?.data?.forgotPassword);
            navigate("/password-reset");
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text mb-1">Forgot Password</h3>
                  <p className="para-content mb-5">
                    Enter your email to reset your password
                  </p>
                </div>

                <div>
                  <div className="form-group first">
                    <label className="label-text mb-2" for="username">
                      Email Id <span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      className="form-control mb-3"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Your email address"
                    ></input>
                  </div>

                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary login-button mt-5 mb-5"
                      type="submit"
                      onClick={handleForgotPassword}
                    >
                      Reset Password
                    </button>
                  </div>

                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
