import React, { useState } from "react";

import GaugeChartComponent from "../../Components/GaugeChartComponent";
import pros from "../../images/icons/pros.png";
import cons from "../../images/icons/cons.png";

import { ProgressBar } from "react-bootstrap";

import ReactStars from "react-rating-stars-component";

const Ratings = ({ allRating }) => {
  const [ratingIndex, setRatingIndex] = useState(0);

  // console.log("DATA", allRating);

  const nextRating = () => {
    if (ratingIndex === allRating?.length - 1) {
      setRatingIndex(allRating?.length - 1);
    } else {
      setRatingIndex(ratingIndex + 1);
    }
  };
  const previousRating = () => {
    if (ratingIndex === 0) {
      setRatingIndex(0);
    } else {
      setRatingIndex(ratingIndex - 1);
    }
  };

  return (
    <section className="your-ratings ms-5 mt-5 me-5">
      <div className="d-flex justify-content-between mb-5">
        <h4>Your Reviews</h4>
        {allRating.length === 0 ? (
          ""
        ) : (
          <div style={{ float: "right" }}>
            <button
              className="btn btn-outline-primary routeButtoncsi mx-2 "
              onClick={previousRating}
              disabled={ratingIndex === 0}
            >
              Previous
            </button>
            <button
              className="btn btn-outline-primary routeButtoncsi mx-2"
              onClick={nextRating}
              disabled={ratingIndex === allRating.length - 1}
            >
              Next
            </button>
          </div>
        )}
      </div>
      <div className="content container-fluid cs">
        {allRating.length === 0 ? (
          <>
            <br />
            <br />
            <h6 className="text-center">
              You haven't received any reviews yet
            </h6>
            <br />
            <br />
          </>
        ) : (
          <div className="row mb-2 justify-content-xl-center justify-content-lg-center">
            <GaugeChartComponent
              title={"One Line Statement"}
              left_label={"It's Confusing"}
              right_label={"Very Clear"}
              value={allRating[ratingIndex]?.one_line_statement}
            />
            <GaugeChartComponent
              title={"Problem Statement"}
              left_label={"It's Confusing"}
              right_label={"Very Clear"}
              value={allRating[ratingIndex]?.problem_statement}
            />
            <GaugeChartComponent
              title={"Problem Solution"}
              left_label={"Not a pressing problem"}
              right_label={"Major Problem"}
              value={allRating[ratingIndex]?.solution_statement}
            />
            <GaugeChartComponent
              title={"Whitepaper"}
              left_label={"Least Likely"}
              right_label={"Most Likely"}
              value={allRating[ratingIndex]?.whitepaper}
            />
            <GaugeChartComponent
              title={"Pitch Deck"}
              left_label={"Poor"}
              right_label={"Outstanding"}
              value={allRating[ratingIndex]?.pitch_deck}
            />
            <GaugeChartComponent
              title={"Product Usage"}
              left_label={"I won't use it"}
              right_label={"I will use it"}
              value={allRating[ratingIndex]?.product_rating}
            />
            <GaugeChartComponent
              title={"Product Competitors"}
              left_label={"High Competition"}
              right_label={"Low Competition"}
              value={allRating[ratingIndex]?.competitive_advantage}
            />
          </div>
        )}
      </div>

      {allRating.length === 0 ? (
        ""
      ) : (
        <>
          <h4 className="mt-3 ms-3 mb-4">Product Feedback</h4>
          <div
            className="row  mb-2 d-flex mx-5"
            style={{ border: "1px solid blue", borderRadius: "10px" }}
          >
            <div className="col-6 my-5 px-5">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                Pros
              </p>
              <ProgressBar
                animated
                striped
                className="mb-5"
                variant="success"
                now={75}
                color={"#55ce63"}
                style={{ height: "10px", width: "90%" }}
              />
              {allRating[ratingIndex]?.pros.length > 0 &&
                allRating[ratingIndex]?.pros.map((i, index) => (
                  <div style={{ display: "flex" }}>
                    <img
                      src={pros}
                      style={{ width: "20px", height: "20px" }}
                    ></img>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#707070",
                      }}
                      className="mb-4 ms-2"
                    >
                      {i}
                    </p>
                  </div>
                ))}
            </div>
            <div className="col-6 my-5 px-5">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                Cons
              </p>
              <ProgressBar
                striped
                animated
                className="mb-5"
                variant="danger"
                now={75}
                style={{ height: "10px", width: "90%" }}
              />
              {allRating[ratingIndex]?.cons.length > 0 &&
                allRating[ratingIndex]?.cons.map((i, index) => (
                  <div style={{ display: "flex" }}>
                    <img
                      src={cons}
                      style={{ width: "20px", height: "20px" }}
                    ></img>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#707070",
                      }}
                      className="mb-4 ms-2"
                    >
                      {i}
                    </p>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-6 mb-5 px-5 text-center">
                <h5>
                  Product Worth (<span className="text-success">$</span>)
                </h5>
                <h6 className="mt-4 ms-3">
                  {allRating[ratingIndex]?.product_worth}
                </h6>
              </div>
              <div className="col-6 mb-5 px-5 text-center">
                <h5>Average Rating </h5>
                {/* <div className=""> */}
                <div className="d-flex justify-content-center">
                  <ReactStars
                    edit={false}
                    value={allRating[ratingIndex]?.average_rating}
                    size={30}
                    activeColor="#ffd700"
                    isHalf={true}
                  />
                </div>
                <h6 className="ms-3">
                  {allRating[ratingIndex]?.average_rating} / 5
                </h6>
                {/* </div> */}
              </div>
              <div className="text-center mb-3">
                <button
                  className="btn btn-primary routeButtoncsi mx-2 "
                  onClick={previousRating}
                  disabled={ratingIndex === 0}
                >
                  Previous
                </button>
                <button
                  className="btn btn-primary routeButtoncsi mx-2"
                  onClick={nextRating}
                  disabled={ratingIndex === allRating.length - 1}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Ratings;
