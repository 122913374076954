import React, { useState } from "react";

import sentinel_logo from "../../images/assests/sentinel-logo.png";

import { IconEye, IconEyeOff } from "@tabler/icons-react";

import { Link, useNavigate } from "react-router-dom";
import LoginFooter from "../../Components/LoginFooter";

import { apiURI } from "../../config";

import { notifyError } from "../../Components/Alerts";

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: "",
  });

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("TESTING EMAIL", re.test(email));
    return re.test(email);
  };

  const handleRegister = async () => {
    let hasError = false;

    // Reset all error messages
    setErrorMessage({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: "",
    });

    if (!name) {
      setErrorMessage((prevState) => ({
        ...prevState,
        name: "Please enter your name.",
      }));
      hasError = true;
    }
    // if (!email) {
    if (!validateEmail(email)) {
      setErrorMessage((prevState) => ({
        ...prevState,
        email: "Please enter a valid email address",
      }));
      hasError = true;
    }
    // }
    if (!password) {
      setErrorMessage((prevState) => ({
        ...prevState,
        password: "Please create a password.",
      }));
      hasError = true;
    }
    if (!confirmPassword) {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Please re-enter your password.",
      }));
      hasError = true;
    }
    if (password !== confirmPassword) {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Passwords do not match.",
      }));
      hasError = true;
    }
    if (!acceptedTerms) {
      setErrorMessage((prevState) => ({
        ...prevState,
        terms: "You must accept the terms and conditions",
      }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    console.log("Registering with", { name, email, password });
    await handleRegisterApi(name, email, password);
  };

  const handleRegisterApi = async (name, email, password) => {
    try {
      var query = `
        mutation CreateFounder($name: String!, $email: String!, $password: String!) {
          createFounder(name: $name, email: $email, password: $password) {
            name
          }
        }  
        `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            email: email,
            password: password,
            name: name,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.createFounder) {
            navigate("/registration-successful");
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Already have an account ? </span>
              <Link
                to="/login"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Login Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text mb-1">Create Your Account</h3>
                  <p className="para-content mb-4">
                    Unlock exclusive access to your product dashboard
                  </p>
                </div>

                <div>
                  <div className="form-group first">
                    <label className="label-text mb-2" for="name">
                      Name <span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      className="form-control mb-3"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      placeholder="What's your name?"
                    ></input>
                    {errorMessage.name !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.name}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group first">
                    <label className="label-text mb-2" for="username">
                      Email Id <span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      className="form-control mb-3"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Your email address"
                    ></input>
                    {errorMessage.email !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.email}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group last mb-3">
                    <label className="label-text mb-2" for="password">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Create a password"
                      ></input>

                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? <IconEyeOff /> : <IconEye />}
                        </button>
                      </div>
                    </div>
                    {errorMessage.password !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.password}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className=" form-group last mb-3">
                    <label
                      className="label-text mb-2"
                      htmlFor="confirm-password"
                    >
                      Confirm Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="Re-enter your password"
                      ></input>

                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        >
                          {confirmPasswordVisible ? (
                            <IconEyeOff />
                          ) : (
                            <IconEye />
                          )}
                        </button>
                      </div>
                    </div>
                    {errorMessage.confirmPassword !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.confirmPassword}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className="checkboxflex"
                    style={{ marginBottom: "10px" }}
                  >
                    <input
                      className=""
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                    <label
                      className="mb-3 mt-3"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      I accept the{" "}
                      <a
                        style={{
                          color: "#1890ff",
                          cursor: "pointer",
                        }}
                        href="https://watcher.finance/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        privacy policy
                      </a>{" "}
                      and the{" "}
                      <a
                        style={{
                          color: "#1890ff",
                          cursor: "pointer",
                        }}
                        href="https://watcher.finance/terms-and-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        terms and conditions
                      </a>{" "}
                      of use
                    </label>
                    {errorMessage.terms !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.terms}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group text-center mb-3">
                    <button
                      className="btn btn-primary login-button"
                      type="button"
                      onClick={handleRegister}
                    >
                      Register
                    </button>
                  </div>

                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
