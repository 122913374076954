// GuestRoute.js
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const GuestRoute = ({ element }) => {
    const { user } = useAuth();
    // return user ? <Navigate to="/dashboard" /> : element;

    if (user) {
        return <Navigate to="/dashboard" />;
    }
    return element;
};

export default GuestRoute;

