import React, { useState } from "react";
import {
  ConnectWallet,
  useAddress,
  useNetworkMismatch,
  useSwitchChain,
  useConnectionStatus,
  useSDK,
} from "@thirdweb-dev/react";
import { OzoneChain } from "@thirdweb-dev/chains";

import { apiURI } from "../../config";
import { useAuth } from "../../Context/AuthContext";
import { Modal, Button } from "react-bootstrap";

import "../../css/productregister.css";

import sentinel_logo from "../../images/assests/sentinel-logo.png";

import {
  productCategories,
  revenueModelsData,
} from "../../Constants/constants";
import MultiSelect from "../../Components/MultiSelect";
import BulletPointsInput from "../../Components/BulletPoints";
import FileUpload from "../../Components/FileUpload";

import {
  notifyError,
  notifyInfo,
  notifySuccess,
  notifyWarning,
} from "../../Components/Alerts";

import { useNavigate } from "react-router-dom";

const ProductRegister = () => {
  const [productName, setProductName] = useState("");
  const [logo, setLogo] = useState(null);
  const [oneLineDesc, setOneLineDesc] = useState("");
  const [productType, setProductType] = useState("");
  const [category, setCategory] = useState([]);
  const [problemStatement, setProblemStatement] = useState(["", ""]);
  const [solutionStatement, setSolutionStatement] = useState(["", ""]);
  const [targetCustomers, setTargetCustomers] = useState(["", ""]);
  const [whitepaper, setWhitepaper] = useState(null);
  const [pitchDeck, setPitchDeck] = useState(null);
  const [revenueModel, setRevenueModel] = useState([]);
  const [solutionBetter, setSolutionBetter] = useState(["", ""]);
  const [competitors, setCompetitors] = useState(["", ""]);
  const [prototypeLink, setPrototypeLink] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [telegramProfile, setTelegramProfile] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    product_name: "",
    one_line_description: "",
    product_type: "",
    product_category: "",
    problem_statement: "",
    revenue_model: "",
    terms: "",
  });

  const [showModal, setShowModal] = useState(false);

  const networkMismatch = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const address = useAddress();
  const sdk = useSDK();
  const navigate = useNavigate();

  const { updateUser } = useAuth();

  const handleInputValidation = async () => {
    let hasError = false;

    // Reset all error messages
    setErrorMessage({
      product_name: "",
      one_line_description: "",
      product_type: "",
      product_category: "",
      problem_statement: "",
      revenue_model: "",
      terms: "",
    });

    if (!productName) {
      setErrorMessage((prevState) => ({
        ...prevState,
        product_name: "Please enter your product name.",
      }));
      hasError = true;
    }

    if (!oneLineDesc) {
      setErrorMessage((prevState) => ({
        ...prevState,
        one_line_description:
          "Please enter your product's one line description.",
      }));
      hasError = true;
    }

    if (!productType) {
      setErrorMessage((prevState) => ({
        ...prevState,
        product_type: "Select you Product Type",
      }));
      hasError = true;
    }

    if (category.length <= 0) {
      setErrorMessage((prevState) => ({
        ...prevState,
        product_category: "Select you Product Category",
      }));
      hasError = true;
    }

    const areAllItemsNonEmpty = problemStatement.every((item) => item !== "");
    if (!areAllItemsNonEmpty) {
      setErrorMessage((prevState) => ({
        ...prevState,
        problem_statement: "Please enter your problem statements",
      }));
      hasError = true;
    }

    if (revenueModel.length <= 0) {
      setErrorMessage((prevState) => ({
        ...prevState,
        revenue_model: "Select you Revenue Model Type",
      }));
      hasError = true;
    }

    if (!acceptedTerms) {
      setErrorMessage((prevState) => ({
        ...prevState,
        terms: "Please accept Terms and Conditions",
      }));
      hasError = true;
    }

    if (hasError) {
      notifyError("Please fill in the mandatory fields");
      return;
    }

    setShowModal(true);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    await handleInputValidation();
    console.log("REGISTERING DATA");
  };

  const handleLogoUpload = (url) => {
    setLogo(url);
  };

  const handleWhitepaperUpload = (url) => {
    setWhitepaper(url);
  };

  const handlePitchDeckUpload = (url) => {
    setPitchDeck(url);
  };

  const handleSignatureRequest = async () => {
    setIsLoading(true);
    const message = `Registering ${productName} on Sentinel`;
    try {
      const signature = await sdk.wallet.sign(message);
      await handleRegisterApi(signature);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      notifyError(
        "Please Accept the Signature Request to Register Your Product"
      );
    }
  };

  const handleRegisterApi = async (signature) => {
    setIsLoading(true);
    const categoryArray = [];
    for (var i = 0; i < category.length; i++) {
      categoryArray.push({
        value: category[i].value,
      });
    }
    const revenueArray = [];
    for (var j = 0; j < revenueModel.length; j++) {
      revenueArray.push({
        value: revenueModel[j].value,
      });
    }

    try {
      var query = `
        mutation RegisterProduct($input: ProductInput) {
          registerProduct(input: $input) {
            _id
          }
        }  
        `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            input: {
              founder: JSON.parse(sessionStorage.getItem("user")).id,
              product_name: productName,
              product_logo: logo,
              one_line_description: oneLineDesc,
              product_type: productType,
              category: categoryArray,
              problem_statement: problemStatement,
              problem_solution: solutionStatement,
              target_customers: targetCustomers,
              whitepaper_link: whitepaper,
              pitch_deck_link: pitchDeck,
              revenue_model: revenueArray,
              competition_reason: solutionBetter,
              competition_links: competitors,
              live_product_link: prototypeLink,
              twitter_link: twitterProfile,
              linkedin_link: linkedinProfile,
              telegram_link: telegramProfile,
              signature: signature,
              wallet_address: address.toString().toLowerCase(),
            },
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.registerProduct) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            const updatedUser = { ...user, hasProductRegistered: true };

            updateUser(updatedUser);

            setTimeout(() => {
              navigate("/dashboard");
            }, 2000);
            setIsLoading(false);
            notifySuccess("Product Registered Successfully");
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
            setIsLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-5 register-container">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <img
            id="logo_header"
            src={sentinel_logo}
            alt="logo-sentinel"
            width="150"
            height="auto"
          ></img>
        </div>
      </div>
      <div>
        <h5 className="mt-3 d-flex align-items-center">
          Let's start the onboarding process for your product with Sentinel.
        </h5>
      </div>
      <div className="form-group  mt-5">
        <label className="label-text-register mb-3" for="username">
          Enter Your Product Name <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            (Cannot be changed after registration) - Provide the official name
            of your project/product.
          </span>
        </label>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="e.g., Sentinel"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        {errorMessage.product_name !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.product_name}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group  mt-3">
        {logo ? (
          <>
            <label className="label-text-register mb-3" for="username">
              Your Logo
            </label>
            <br />
            <img
              id="logo_header"
              src={logo}
              alt="logo"
              width="150"
              height="auto"
            ></img>
            <button
              className="btn btn-sm btn-danger ms-5"
              onClick={() => setLogo(null)}
            >
              Remove
            </button>
          </>
        ) : (
          <>
            <label className="label-text-register mb-3" for="username">
              Upload Your Logo
              <br />
              <span className="sub-label-text-register">
                (Recommended size: 512 x 512px) - Upload an image file for your
                product's logo.
              </span>
            </label>

            <FileUpload onUploadComplete={handleLogoUpload} />
          </>
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Describe Your Product in One Line{" "}
          <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            Keep it simple and precise so that a layman can understand it
          </span>
        </label>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="e.g., A decentralized platform for secure financial transactions"
          value={oneLineDesc}
          onChange={(e) => setOneLineDesc(e.target.value)}
        />
        {errorMessage.one_line_description !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.one_line_description}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Select Your Product Type <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            Choose the appropriate type that best describes your product.
          </span>
        </label>
        <select
          class="form-select mb-3"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <option value="" disabled>
            Select product type
          </option>
          <option value="B2B">B2B</option>
          <option value="B2C">B2C</option>
          <option value="B2B2C">B2B2C</option>
        </select>
        {errorMessage.product_type !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.product_type}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Select Your Category <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            Choose the relevant category that fits your product's industry or
            function.
          </span>
        </label>
        <MultiSelect
          options={productCategories}
          selected={category}
          onChange={setCategory}
          placeholder={"Select Product Category"}
        />
        {errorMessage.product_category !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.product_category}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Describe Your Problem Statement{" "}
          <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            (In bullet points) - List the major problems your product is
            tackling
          </span>
        </label>
        <BulletPointsInput
          parentState={problemStatement}
          setParentState={setProblemStatement}
        />
        {errorMessage.problem_statement !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.problem_statement}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Describe Your Solution
          <br />
          <span className="sub-label-text-register">
            (In bullet points) - Outline the solutions your product is
            proposing.
          </span>
        </label>
        <BulletPointsInput
          parentState={solutionStatement}
          setParentState={setSolutionStatement}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Identify Your Target Customers
          <br />
          <span className="sub-label-text-register">
            (Make a list) - Specify your target customers, e.g., financial
            institutions, hospitals,
          </span>
        </label>
        <BulletPointsInput
          parentState={targetCustomers}
          setParentState={setTargetCustomers}
        />
      </div>

      <div className="form-group  mt-3">
        {whitepaper ? (
          <>
            <label className="label-text-register mb-3" for="username">
              Your WhitePaper
            </label>
            <br />
            <a
              href={whitepaper}
              alt="whitepaper"
              target="_blank"
              rel="noreferrer"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              View WhitePaper
            </a>
            <button
              className="btn btn-sm btn-danger ms-5"
              onClick={() => setWhitepaper(null)}
            >
              Remove Whitepaper
            </button>
          </>
        ) : (
          <>
            <label className="label-text-register mb-3" for="username">
              Do You Have a Whitepaper?
              <br />
              <span className="sub-label-text-register">
                Upload your whitepaper if available (File format: PDF only).
              </span>
            </label>

            <FileUpload onUploadComplete={handleWhitepaperUpload} />
          </>
        )}
      </div>

      <div className="form-group  mt-3">
        {pitchDeck ? (
          <>
            <label className="label-text-register mb-3" for="username">
              Your PitchDeck
            </label>
            <br />
            <a
              href={pitchDeck}
              alt="PitchDeck"
              target="_blank"
              rel="noreferrer"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              View PitchDeck
            </a>
            <button
              className="btn btn-sm btn-danger ms-5"
              onClick={() => setPitchDeck(null)}
            >
              Remove PitchDeck
            </button>
          </>
        ) : (
          <>
            <label className="label-text-register mb-3" for="username">
              Do You Have a Pitch Deck?
              <br />
              <span className="sub-label-text-register">
                Upload your pitch deck if available (File format: PDF only).
              </span>
            </label>

            <FileUpload onUploadComplete={handlePitchDeckUpload} />
          </>
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Select Your Revenue Model <span style={{ color: "red" }}>*</span>
          <br />
          <span className="sub-label-text-register">
            Select whether you have an established revenue model.
          </span>
        </label>
        <MultiSelect
          options={revenueModelsData}
          placeholder={"Select Your Revenue Model"}
          value={revenueModel}
          onChange={setRevenueModel}
        />
        {errorMessage.revenue_model !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.revenue_model}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Why Is Your Solution Better Than Your Competition ?
          <br />
          <span className="sub-label-text-register">
            (Tell us what makes you different) - Describe the unique aspects of
            your solution in bullet points.
          </span>
        </label>
        <BulletPointsInput
          parentState={solutionBetter}
          setParentState={setSolutionBetter}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Do You Have Competitors?
          <br />
          <span className="sub-label-text-register">
            (Update your competitors' website link) - Provide the links to your
            competitors' websites.
          </span>
        </label>
        <BulletPointsInput
          parentState={competitors}
          setParentState={setCompetitors}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Do You Have a Live Product or Working Prototype?
          <br />
          <span className="sub-label-text-register">
            (If Yes, please provide the link) - Provide the URL to your product
            or prototype.
          </span>
        </label>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="e.g.https://app.yourproduct.com"
          value={prototypeLink}
          onChange={(e) => setPrototypeLink(e.target.value)}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Twitter Profile
          <br />
          <span className="sub-label-text-register">
            Provide the link to your product's Twitter profile.
          </span>
        </label>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="https://twitter.com/yourprofile"
          value={twitterProfile}
          onChange={(e) => setTwitterProfile(e.target.value)}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          LinkedIn Profile
          <br />
          <span className="sub-label-text-register">
            Provide the link to your product's LinkedIn profile.
          </span>
        </label>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="https://linkedin.com/in/yourprofile"
          value={linkedinProfile}
          onChange={(e) => setLinkedinProfile(e.target.value)}
        />
      </div>

      <div className="form-group mt-3">
        <label className="label-text-register mb-3" for="username">
          Telegram Profile
          <br />
          <span className="sub-label-text-register">
            Provide the link to your product's Telegram profile.
          </span>
        </label>

        <input
          className="form-control mb-3"
          type="text"
          placeholder="https://telegram.com/profile"
          value={telegramProfile}
          onChange={(e) => setTelegramProfile(e.target.value)}
        />
      </div>

      <br />

      <div className="checkboxflex" style={{ marginBottom: "10px" }}>
        <input
          className=""
          type="checkbox"
          style={{
            marginRight: "10px",
            borderRadius: "20px",
            cursor: "pointer",
          }}
          checked={acceptedTerms}
          onChange={(e) => setAcceptedTerms(e.target.checked)}
        />
        <label
          className="mb-3 mt-3"
          style={{ fontWeight: "500", fontSize: "14px" }}
        >
          I understand and agree that this product will be subjected to ratings
          by users.
        </label>
        {errorMessage.terms !== "" ? (
          <p className="error-vesting-text" style={{ width: "100%" }}>
            {errorMessage.terms}
          </p>
        ) : (
          ""
        )}
      </div>
      <br />
      <br />

      <div className="form-group text-center mb-3">
        {address === undefined ? (
          <ConnectWallet
            style={{
              textColor: "#fff",
              background: "blue",
            }}
            theme={"light"}
            modalTitle={"Connect Wallet"}
            switchToActiveChain={true}
          />
        ) : networkMismatch ? (
          <button
            id="switchNetwork"
            className="btn btn-primary save-button-register"
            onClick={() => switchChain(OzoneChain.chainId)}
            style={{ cursor: "pointer" }}
          >
            Switch Network
          </button>
        ) : (
          <button
            className="btn btn-primary save-button-register"
            type="submit"
            onClick={handleRegister}
          >
            Register Product
          </button>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Please review the information below before proceeding:</h5>

          <h6>Benefits of Registering Your Project</h6>
          <p>
            Registering your project with Sentinel provides you with a range of
            benefits, including increased visibility, access to our community of
            users, and the ability to receive feedback and ratings that can help
            you improve your product. Make sure all the information provided is
            accurate and up-to-date to make the most of these opportunities.
          </p>

          <h6>Important Information</h6>
          <p>
            By confirming the registration, you agree that your product will be
            subjected to ratings and reviews by our user base. This will help in
            gaining trust and credibility among potential users and investors.
            We highly recommend double-checking all details before proceeding.
          </p>

          <h6>Need Assistance?</h6>
          <p>
            If you have any questions or need assistance, please feel free to
            reach out to our support team. We are here to help you every step of
            the way. Thank you for choosing Sentinel to showcase and grow your
            project.
          </p>

          <h6 className="text-danger">Please Note</h6>
          <p className="text-danger">
            This action cannot be undone. Once you register this project, the
            product name cannot be changed. However, don't worry—you can always
            update other details of your product on your product page at any
            time.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSignatureRequest}
            disabled={isLoading ? true : false}
          >
            Register Product
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductRegister;
