// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bullet-point-container {
    display: flex;
    /* align-items: center;s */
    margin-bottom: 10px;

}

.bullet {
    font-size: 20px;
    margin-right: 10px;
}

.bullet-point-input {
    flex: 1 1;
    padding: 10px;
    border-radius: 4px;
    /* border: 1px solid #c9c2c2; */
    font-size: 16px;
    margin-bottom: 12px;
}

.remove-button {
    padding: 10px 10px;
    border-radius: 20%;
    margin-left: 10px;
    border: 0px;
    margin-bottom: 12px;


    /* font-size: 15px; */
    /* background: linear-gradient(90deg, #6345ED, #DC39FC); */
    /* color: white; */
}

.add-button {
    padding: 10px 10px;
    border-radius: 20%;
    margin-left: 10px;
    border: 0px;
    margin-bottom: 12px;
    /* background: linear-gradient(90deg, #6345ED, #DC39FC); */
    /* color: white; */
    /* font-size: 16px; */

}`, "",{"version":3,"sources":["webpack://./src/css/bulletpoints.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;;AAEvB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;;;IAGnB,qBAAqB;IACrB,0DAA0D;IAC1D,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,0DAA0D;IAC1D,kBAAkB;IAClB,qBAAqB;;AAEzB","sourcesContent":[".bullet-point-container {\n    display: flex;\n    /* align-items: center;s */\n    margin-bottom: 10px;\n\n}\n\n.bullet {\n    font-size: 20px;\n    margin-right: 10px;\n}\n\n.bullet-point-input {\n    flex: 1;\n    padding: 10px;\n    border-radius: 4px;\n    /* border: 1px solid #c9c2c2; */\n    font-size: 16px;\n    margin-bottom: 12px;\n}\n\n.remove-button {\n    padding: 10px 10px;\n    border-radius: 20%;\n    margin-left: 10px;\n    border: 0px;\n    margin-bottom: 12px;\n\n\n    /* font-size: 15px; */\n    /* background: linear-gradient(90deg, #6345ED, #DC39FC); */\n    /* color: white; */\n}\n\n.add-button {\n    padding: 10px 10px;\n    border-radius: 20%;\n    margin-left: 10px;\n    border: 0px;\n    margin-bottom: 12px;\n    /* background: linear-gradient(90deg, #6345ED, #DC39FC); */\n    /* color: white; */\n    /* font-size: 16px; */\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
