import React, { useState } from "react";
import sentinel_logo from "../../images/assests/sentinel-logo.png";
import verified_image from "../../images/assests/verified-image.png";

import { Link } from "react-router-dom";
import LoginFooter from "../../Components/LoginFooter";

const Verification_Successful = () => {
  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            {/* <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div> */}
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block-1 mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text-header-register mb-2">
                    Email Verified Successfully!
                  </h3>
                  <div>
                    <img
                      id="logo_header"
                      src={verified_image}
                      alt="logo-sentinel"
                      width="450"
                      height="auto"
                    ></img>
                  </div>

                  <h4 className="sub-label-text-verified-bold  mt-4">
                    Your email has been successfully verified.
                  </h4>
                  <p className="sub-label-text-verified">
                    You can now log in to your account and start using the
                    platform. Thank you for confirming your email address.
                  </p>
                </div>

                <div className="form-group text-center mt-5">
                  <a
                    className="btn btn-primary login-button w-25"
                    href="/login"
                  >
                    Login Now
                  </a>
                </div>

                <div>
                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification_Successful;
