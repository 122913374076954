export const productCategories = [
    { value: "AMM", label: "AMM" },
    { value: "ADVERTISING", label: "ADVERTISING" },
    { value: "BLOCKCHAIN INFRASTRUCTURE", label: "BLOCKCHAIN INFRASTRUCTURE" },
    { value: "BLOCKCHAIN SERVICE", label: "BLOCKCHAIN SERVICE" },
    { value: "CEFI", label: "CEFI" },
    { value: "CEX", label: "CEX" },
    { value: "CROSS-CHAIN", label: "CROSS-CHAIN" },
    { value: "DATA SERVICE", label: "DATA SERVICE" },
    { value: "DEFI", label: "DEFI" },
    { value: "DEX", label: "DEX" },
    { value: "DIGITAL IDENTITY", label: "DIGITAL IDENTITY" },
    { value: "EXCHANGE", label: "EXCHANGE" },
    { value: "GAMEFI", label: "GAMEFI" },
    { value: "GAMING GUILD", label: "GAMING GUILD" },
    { value: "GOVERNANCE", label: "GOVERNANCE" },
    { value: "INSURANCE", label: "INSURANCE" },
    { value: "LAUNCHPAD", label: "LAUNCHPAD" },
    { value: "MEME", label: "MEME" },
    { value: "METAVERSE", label: "METAVERSE" },
    { value: "MOVE TO EARN(M2E)", label: "MOVE TO EARN(M2E)" },
    { value: "MOVE2EARN", label: "MOVE2EARN" },
    { value: "NFT", label: "NFT" },
    { value: "NFT MARKETPLACE", label: "NFT MARKETPLACE" },
    { value: "PAYMENTS", label: "PAYMENTS" },
    { value: "PLAY TO EARN(P2E)", label: "PLAY TO EARN(P2E)" },
    { value: "SOCIAL", label: "SOCIAL" },
    { value: "YIELD FARMING", label: "YIELD FARMING" },
    { value: "ASSET BACKED", label: "ASSET BACKED" },
    { value: "CLOUD SERVICES", label: "CLOUD SERVICES" },
    { value: "EDUCATION", label: "EDUCATION" },
    { value: "GAMBLING", label: "GAMBLING" },
    { value: "HEALTHCARE", label: "HEALTHCARE" },
    { value: "LAYER-1", label: "LAYER-1" },
    { value: "LAYER-2", label: "LAYER-2" },
    { value: "KYC/AML", label: "KYC/AML" },
    { value: "OTHERS", label: "OTHERS" },
];

export const revenueModelsData = [
    { value: "One Time Sale", label: "One Time Sale" },
    { value: "Subscription Based", label: "Subscription Based" },
    { value: "Usage Based", label: "Usage Based" },
    { value: "Advertising", label: "Advertising" },
    { value: "Freemium", label: "Freemium" },
    { value: "Licensing", label: "Licensing" },
    { value: "Service based", label: "Service based" },
    { value: "Others", label: "Others" },
    { value: "Not Decided Yet", label: "Not Decided Yet" },
];

