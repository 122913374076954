import React from "react";

import sentinel_logo from "../images/assests/sentinel-logo.png";
import { Link } from "react-router-dom";
import {
  IconHome,
  IconBox,
  IconSettings,
  IconLogout,
} from "@tabler/icons-react";

import { useAuth } from "../Context/AuthContext";

const Header = () => {
  const { logout } = useAuth();
  return (
    <>
      <div className="mt-3 d-flex align-items-center justify-content-between header-content ">
        <div className="ms-5">
          <img
            id="logo_header"
            src={sentinel_logo}
            alt="logo-sentinel"
            width="150"
            height="auto"
            className="header-logo"
          ></img>
        </div>
        <div className="me-5 header-nav">
          <Link to="/dashboard" className="signUp link">
            <IconHome className="me-1" />
            Dashboard
          </Link>
          <Link to="/product" className="signUp link ">
            <IconBox className="me-1" />
            My Product
          </Link>
          {/* <Link to="/product-register" className="signUp link">
            <IconSettings className="me-1" />
            Settings
          </Link> */}
          <Link to="/login" className="signUp link" onClick={logout}>
            <IconLogout className="me-1" />
            Logout
          </Link>
        </div>
      </div>
      <hr className="mx-auto" style={{ width: "95%" }} />
    </>
  );
};

export default Header;
