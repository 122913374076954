import React from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const MultiSelect = ({ options, placeholder, value, onChange }) => {
  return (
    <CreatableSelect
      isClearable
      options={options}
      isMulti
      placeholder={placeholder}
      components={animatedComponents}
      allowSelectAll={true}
      onChange={onChange}
      value={value}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          cursor: "pointer",
          backdropFilter: "blur(16px)",
          backgroundColor: "#ffffff30 !important",
          border: "1px solid #ccc !important",
          borderRadius: "4px !important",
          zIndex: 10,
        }),
      }}
    />
  );
};

export default MultiSelect;
