import React, { useState } from "react";

import sentinel_logo from "../../images/assests/sentinel-logo.png";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiURI } from "../../config";

import { notifyError, notifySuccess } from "../../Components/Alerts";

const ResetPassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user = searchParams.get("user");
  const auth = searchParams.get("auth");

  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    password: "",
    confirmPassword: "",
  });

  const handlePasswordReset = async () => {
    let hasError = false;

    // Reset all error messages
    setErrorMessage({
      password: "",
      confirmPassword: "",
    });

    if (!password) {
      setErrorMessage((prevState) => ({
        ...prevState,
        password: "Please create a password.",
      }));
      hasError = true;
    }
    if (!confirmPassword) {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Please re-enter your password.",
      }));
      hasError = true;
    }
    if (password !== confirmPassword) {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Passwords do not match.",
      }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    await handlePasswordResetApi(user, auth, password);
  };

  const handlePasswordResetApi = async (user, auth, password) => {
    try {
      var query = `
          mutation Mutation($id: ID!, $secretCode: String!, $password: String!) {
            resetPassword(_id: $id, secretCode: $secretCode, password: $password)
          }
          `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            id: user,
            secretCode: auth,
            password,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.resetPassword) {
            notifySuccess(data?.data?.resetPassword);
            navigate("/login");
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  // http://localhost:5676/reset-password?user=66977e0b9b5d6b2fe87ca90d&&auth=FBi78FIRW4K3ySl

  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text mb-5">Update Your Password</h3>
                  {/* <p className="para-content mb-4">
                    Enter your email to reset your password
                  </p> */}
                </div>

                <div>
                  <div className="form-group last mb-3">
                    <label className="label-text mb-2" for="password">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Create a new password"
                      ></input>

                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? <IconEyeOff /> : <IconEye />}
                        </button>
                      </div>
                    </div>
                    {errorMessage.password !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.password}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className=" form-group last mb-3">
                    <label
                      className="label-text mb-2"
                      htmlFor="confirm-password"
                    >
                      Confirm Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="Re-enter your password"
                      ></input>

                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        >
                          {confirmPasswordVisible ? (
                            <IconEyeOff />
                          ) : (
                            <IconEye />
                          )}
                        </button>
                      </div>
                    </div>
                    {errorMessage.confirmPassword !== "" ? (
                      <p
                        className="error-vesting-text"
                        style={{ width: "100%" }}
                      >
                        {errorMessage.confirmPassword}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group text-center mt-5 mb-3">
                    <button
                      className="btn btn-primary login-button"
                      type="button"
                      onClick={handlePasswordReset}
                    >
                      Update Password
                    </button>
                  </div>
                  {/* <span className="text-center my-2 d-block">or</span> */}
                  {/* <div className="">
                <a href="#" className="btn btn-block py-2 btn-google"><span className="mr-2"><img className="g-icon" src="images/g-icon.png" alt="g-icon"></img></span> Login with Google</a>
                </div> */}
                  <p className="ver-p">Ver 01.07 Dated : 24/03/2023</p>
                  <p className="link-a-t">
                    <a
                      href="https://watcher.finance/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      watcher.finance{" "}
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://crsquare.finance/terms-and-conditions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
