import React, { useState } from "react";
import sentinel_logo from "../../images/assests/sentinel-logo.png";

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import LoginFooter from "../../Components/LoginFooter";

import { apiURI } from "../../config";
import { useAuth } from "../../Context/AuthContext";

import { notifyError } from "../../Components/Alerts";

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Logging in with", email, password);
    await loginFounder(email, password);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginFounder = async (email, password) => {
    try {
      var query = `
          mutation Mutation($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              hasProductRegistered
              name
              id
            }
          }

          `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            email,
            password,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.login) {
            login(data?.data?.login);
            if (!data?.data?.login.hasProductRegistered) {
              navigate("/dashboard");
            } else {
              navigate("/register-product");
            }
          } else {
            console.log(data.errors[0].message);
            notifyError(data.errors[0].message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text mb-1">Welcome to Sentinel</h3>
                  <p className="para-content mb-4">
                    Login to access your projects
                  </p>
                </div>

                <div>
                  <div className="form-group first">
                    <label className="label-text mb-2" for="username">
                      Email Id <span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      className="form-control mb-3"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Your email address"
                    />
                  </div>

                  <div className="last mb-1">
                    <label className="label-text mb-2" for="password">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        className="form-control"
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Enter your password"
                      />

                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <IconEyeOff /> : <IconEye />}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-sm-flex mb-3 align-items-center">
                    <Link to="/forgot-password" className="forgot-pass">
                      Forgot Password?
                    </Link>
                  </div>

                  <div className="form-group text-center mb-3">
                    <button
                      className="btn btn-primary login-button"
                      type="submit"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>

                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
