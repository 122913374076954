import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Card from "../../Components/Card";

const AllProducts = ({ allProducts }) => {
  const navigate = useNavigate();
  const [sortMode, setSortMode] = useState("none"); // add sortMode state variable
  const [isDescending, setIsDescending] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let sortedProducts =
    sortMode === "name"
      ? [...allProducts].sort((a, b) =>
          a.product_name.localeCompare(b.product_name, undefined, {
            sensitivity: "base",
          })
        )
      : sortMode === "type"
      ? [...allProducts].sort((a, b) =>
          a.product_type.localeCompare(b.product_type, undefined, {
            sensitivity: "base",
          })
        )
      : allProducts;

  sortedProducts = isDescending ? sortedProducts.reverse() : sortedProducts;

  let filteredProducts = sortedProducts.filter((product) =>
    product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // console.log("PRODUCTS", filteredProducts);

  var settings = {
    speed: 500,
    rows: filteredProducts.length < 6 ? 1 : 2,
    slidesToShow: filteredProducts.length <= 4 ? filteredProducts.length : 4,
    swipeToSlide: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            filteredProducts.length <= 2 ? filteredProducts.length : 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow:
            filteredProducts.length <= 2 ? filteredProducts.length : 2,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // customPaging: function(i) {
          //   return (
          //       <p>1</p>
          //   );
          // },
          slidesToShow: 1,
          dots: true,
          dotsClass: "slick-dots slick-thumb",
          arrows: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      {/* <div class="row pt-0"> */}
      {/* <div class="w-75">
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-cloud"
            role="tabpanel"
            aria-labelledby="pills-cloud-tab"
          > */}
      <div>
        <h6 style={{ width: "150px" }}>All Products &nbsp;</h6>
        <div class="row pb-4  ms-5 me-5">
          <div className="mb-4 mt-4 all-ideas-text">
            <div className="all-ideas-text mb-4 search-div">
              <input
                className="input-search"
                type="text"
                placeholder="🔎 Search by Product name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <h6 style={{ display: "flex", alignItems: "baseline" }}>
              <button
                className={`btn btn-link all-ideas-text-btn ${
                  sortMode === "name" ? "active" : ""
                } `}
                onClick={() => {
                  if (sortMode === "name") {
                    setIsDescending(!isDescending);
                  } else {
                    setIsDescending(false);
                    setSortMode("name");
                  }
                }}
                data-sort="type"
              >
                Sort by Name
                {sortMode === "name" &&
                  (isDescending ? (
                    <span style={{ color: "blue" }}>▲</span>
                  ) : (
                    <span>▼</span>
                  ))}
              </button>
              <button
                className={`btn btn-link all-ideas-text-btn  ${
                  sortMode === "type" ? "active" : ""
                } `}
                onClick={() => {
                  if (sortMode === "type") {
                    setIsDescending(!isDescending);
                  } else {
                    setIsDescending(false);
                    setSortMode("type");
                  }
                }}
              >
                Sort by Type
                {sortMode === "type" &&
                  (isDescending ? <span>▲</span> : <span>▼</span>)}
              </button>
            </h6>
          </div>

          {filteredProducts.length === 0 ? (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h6 className="mt-5" style={{ textAlign: "center" }}>
                No Product's Found Matching your Text
              </h6>
              <br />
              <br />
            </div>
          ) : (
            <Slider {...settings}>
              {filteredProducts.map((value, index) => {
                return <Card product={value} />;
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AllProducts;
