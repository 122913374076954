import React from "react";

const LoginFooter = () => {
  return (
    <>
      <p className="ver-p">Version 1.04 August,2024</p>
      <p className="link-a-t">
        <a href="https://watcher.finance/" target="_blank" rel="noreferrer">
          Watcher.Finance{" "}
        </a>{" "}
        |{" "}
        <a
          href="https://watcher.finance/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>
      </p>
    </>
  );
};

export default LoginFooter;
