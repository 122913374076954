import React, { useState } from "react";
import sentinel_logo from "../../images/assests/sentinel-logo.png";
import email_image from "../../images/assests/email-image.png";

import { IconCircleCheck } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import LoginFooter from "../../Components/LoginFooter";

const Registration_Successful = () => {
  return (
    <div>
      <div className="contents background-image">
        <div className="container mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                id="logo_header"
                src={sentinel_logo}
                alt="logo-sentinel"
                width="150"
                height="auto"
              ></img>
            </div>
            <div>
              <span className="text-account">Don't have an Account ? </span>
              <Link
                to="/register"
                style={{ color: "#6345ED", marginRight: "13px" }}
                className="signUp"
              >
                Register Now
              </Link>
            </div>
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ minHeight: "90vh", height: "auto" }}
          >
            <div className="col-md-12">
              <div className="form-block-1 mx-auto mb-1 ">
                <div className="text-center">
                  <h3 className="text-header-register mb-1">
                    Registration Successful!
                  </h3>
                  <div>
                    <img
                      id="logo_header"
                      src={email_image}
                      alt="logo-sentinel"
                      width="450"
                      height="auto"
                    ></img>
                  </div>

                  <h4 className="sub-label-text-verified-bold ">
                    Thank you for registering with us!
                  </h4>
                  <p className="sub-label-text-verified">
                    To complete your registration, please verify your email
                    address.
                  </p>
                  <p className="sub-label-text-verified">
                    We've sent a verification email to the address you provided.
                    Please check your inbox (and spam folder) for an email from
                    Sentinal.
                  </p>
                  <p className="sub-label-text-verified">
                    Click on the verification link in the email to activate your
                    account.
                  </p>

                  <br />
                  <br />
                  <p>
                    <strong>Haven't received the email?</strong>
                  </p>
                  <ul>
                    <p className="mb-1">Check your spam or junk folder.</p>
                    {/* <p className="mb-1">Resend verification email</p> */}
                    <p className="mb-1">
                      Contact our support team :{" "}
                      <a
                        href="https://watcher.finance/contact/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "blue" }}
                      >
                        contact@watcher.finance
                      </a>
                    </p>
                  </ul>
                </div>

                <div>
                  <LoginFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration_Successful;
