import React from "react";

import GaugeChart from "react-gauge-chart";
const GaugeChartComponent = ({ left_label, right_label, title, value }) => {
  return (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-5">
      <div className="ideadet mb-2 cs">
        <p
          className="mb-2"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {title}
        </p>
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={3}
          colors={["#6345ED ", "#DC39FC"]}
          needleColor={"rgb(187 181 215)"}
          needleBaseColor={"rgb(109 101 149)"}
          arcWidth={0.2}
          percent={value / 5}
          textColor={"black"}
          hideText={true}
          cornerRadius={2}
        />
        <div>
          <p
            style={{
              fontSize: "11px",
              fontWeight: "500",
              color: "black",
              float: "left",
            }}
          >
            {left_label}
          </p>
          <p
            style={{
              fontSize: "11px",
              fontWeight: "500",
              color: "black",
              float: "right",
            }}
          >
            {right_label}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GaugeChartComponent;
